import Header from './landing/Header'
import Footer from './Footer'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import CTA from './landing/CTA'

const faqs = [
  {
    question: 'Does someone need to be present during my appointment?',
    answer:
      'Whether or not someone’s present during the appointment is up to you! Before we arrive, you’ll provide us with entry instructions to your home. If you have a doorman or can hide a key somewhere, there’s no need for you to be home. You’re also free to stick around during the clean, whatever you’re most comfortable with.'
  },
  {
    question: 'Is Qinti Cleaning insured and bonded?',
    answer:
      'Yes, Qinti Cleaning is insured and bonded. We understand the trust it takes to let someone into your home, and we’re always careful. In the rare event that an object is damaged, please notify us within 48 hours of the appointment either by email, contact@qinticleaning.com , or by phone (310) 623-7094'
  },
  {
    question: 'Is Qinti Cleaning pet safe?',
    answer:
      'We’re happy to work in your home around pets, but if they may become overly anxious while we’re there, please make temporary arrangements while we are in your home. We will ask about pets during the online booking process.'
  },
  {
    question: 'Do I need to provide cleaning supplies?',
    answer:
      'We use and provide environmentall friendly supplies and all equipment required for cleaning your home, including the following: Vacuum, Mop and bucket, step stool, toilet brush, speacialty products. If you’d like us to use a specific product for your home, please make sure they’re left out in clear view for our cleaning staff and accompanied by directions on their use.'
  },
  {
    question: 'What if I just had renovation work done?',
    answer:
      'We provide post-construction cleaning for those types of situations. Fill out our booking form below!'
  },
  {
    question: 'Can I give specific instructions to the cleaners and ask for special requests?',
    answer:
      'Yes, special instructions can be left for the cleaning professionals, just let us know before we confirm your appointment.'
  },
  {
    question: 'How do I get an office cleaning quote?',
    answer:
      "Don't use our form below, that's spefically for homes. Instead, send us an email at contact@qinticleaning.com including the name of the business and a square footage estimate and we’ll respond promptly."
  },
  {
    question: 'What is the Qinti Guarantee?',
    answer: 'We get it right or we make it right. If something isn’t right with your clean, we’ll go back and fix it for free.'
  },
  {
    question: 'How to request a reclean?',
    answer: 'Customers can requesta  reclean either by email (contact@myclean.com) or by phone (310) 623-7094 as long as the appointment was completed within the past 48 hours. Please let us know where we fell short. Our customer service team will respond promptly.'
  },
  {
    question: 'How do I pay for my clean?',
    answer: 'We accept all major credit cards and debit cards with a MasterCard, Visa, Discover, UnionPay, JCB, Diners Club, and American Express logo. We also accept cash and Venmo.'
  },
  {
    question: 'What is Qinti Cleaning’s tipping policy?',
    answer: 'Tipping is optional and not expected. You are free to tip, and cleaners are free to accept, but not request tips, at any time. Unfortunately, we don’t currently have a way to accept tips online, so we ask that if you wish to tip you do so in cash.'
  },
  {
    question: 'What is your Cancellation Policy?',
    answer: 'We assess a $50 cancellation fee to appointments not cancelled within 24 hours of the appointment. We do this because it takes lots of time to build out optimal and efficient routes for cleaners and it’s often impossible to find another home to take its place.'
  }
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}
function Questions () {
  return (
    <>
      <Header />
      <div className='bg-white'>
        <div className='max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <h2 className='text-base font-semibold text-yale tracking-wide uppercase'>Frequently Asked Questions</h2>
            <p className='mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl'>
              Questions? Look here.
            </p>
            <p className='max-w-xl mt-5 mx-auto text-xl text-gray-500'>
              Can't find an answer? Call us at (310) 623-7094 or email us at contact@qinticleaning.com.
            </p>
          </div>
        </div>
      </div>
      <div className='bg-gray-50'>
        <div className='max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8'>
          <div className='max-w-3xl mx-auto divide-y-2 divide-gray-200'>
            <h3 className='text-center text-3xl font-extrabold text-gray-900 sm:text-4xl'>The answers you're looking for:</h3>
            <dl className='mt-6 space-y-6 divide-y divide-gray-200'>
              {faqs.map((faq) => (
                <Disclosure as='div' key={faq.question} className='pt-6'>
                  {({ open }) => (
                    <>
                      <dt className='text-lg'>
                        <Disclosure.Button className='text-left w-full flex justify-between items-start text-gray-400'>
                          <span className='font-medium text-gray-900'>{faq.question}</span>
                          <span className='ml-6 h-7 flex items-center'>
                            <ChevronDownIcon
                              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                              aria-hidden='true'
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as='dd' className='mt-2 pr-12'>
                        <p className='text-base text-gray-500'>{faq.answer}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <CTA />
      <Footer />
    </>
  )
}
export default Questions
