import formatPhoneNumber from './utility/formatPhoneNumber'

export default function Contact ({ req, setReq }) {
  const handleChange = (e, field) => {
    setReq(req => {
      const newReq = JSON.parse(JSON.stringify(req))
      newReq.contact[field] = field === 'phone' ? formatPhoneNumber(e.target.value) : e.target.value
      return newReq
    })
  }

  return (
    <form action='#' method='POST'>
      <div className='overflow-hidden sm:rounded-md'>
        <div className='sm:p-0'>
          <div className='grid grid-cols-6 gap-6'>
            <div className='col-span-3 sm:col-span-3'>
              <label htmlFor='first_name' className='block text-sm font-medium text-gray-700'>
                First name
              </label>
              <input
                onChange={e => handleChange(e, 'first_name')}
                value={req.contact.first_name}
                type='text'
                name='first_name'
                id='first_name'
                autoComplete='given-name'
                className='mt-1 focus:ring-transparent focus:border-alice block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>
            <div className='col-span-3 sm:col-span-3'>
              <label htmlFor='last_name' className='block text-sm font-medium text-gray-700'>
                Last name
              </label>
              <input
                onChange={e => handleChange(e, 'last_name')}
                value={req.contact.last_name}
                type='text'
                name='last_name'
                id='last_name'
                autoComplete='family-name'
                className='mt-1 focus:ring-transparent focus:border-alice block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>
            <div className='col-span-6 sm:col-span-3'>
              <label htmlFor='phone_number' className='block text-sm font-medium text-gray-700'>
                Phone Number
              </label>
              <input
                onChange={(e) => handleChange(e, 'phone')}
                value={req.contact.phone}
                type='text'
                name='phone_number'
                id='phone_number'
                autoComplete='phone'
                placeholder='(123) 456-7890'
                className='placeholder-gray-300  mt-1 focus:ring-transparent focus:border-alice block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>
            <div className='col-span-6 sm:col-span-3'>
              <label htmlFor='zip' className='block text-sm font-medium text-gray-700'>
                Zip Code
              </label>
              <input
                onChange={(e) => handleChange(e, 'zip')}
                value={req.contact.zip}
                type='text'
                name='zip'
                id='zip'
                autoComplete='zip'
                placeholder='90277'
                className='placeholder-gray-300  mt-1 focus:ring-transparent focus:border-alice block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
