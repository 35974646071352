import Header from './landing/Header'
import Footer from './Footer'

import { CameraIcon } from '@heroicons/react/solid'
import homeCleaningImg from '../assets/home-cleaning.jpg'
import CTA from './landing/CTA'

function HomeCleaning () {
  return (
    <>
      <Header />
      <div className='bg-white overflow-hidden'>
        <div className='relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8'>
          <div className='hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen' />
          <div className='mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none'>
            <div>
              <h2 className='text-base text-yale font-semibold tracking-wide uppercase'>Personalized Services</h2>
              <h3 className='mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
                Home Cleaning
              </h3>
            </div>
          </div>
          <div className='mt-8 lg:grid lg:grid-cols-2 lg:gap-8'>
            <div className='relative lg:row-start-1 lg:col-start-2'>
              <svg
                className='hidden lg:block absolute top-0 right-0 -mt-20 -mr-20'
                width={404}
                height={384}
                fill='none'
                viewBox='0 0 404 384'
                aria-hidden='true'
              >
                <defs>
                  <pattern
                    id='de316486-4a29-4312-bdfc-fbce2132a2c1'
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits='userSpaceOnUse'
                  >
                    <rect x={0} y={0} width={4} height={4} className='text-gray-200' fill='currentColor' />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill='url(#de316486-4a29-4312-bdfc-fbce2132a2c1)' />
              </svg>
              <div className='relative text-base mx-auto max-w-prose lg:max-w-none'>
                <figure>
                  <div className='aspect-w-12 aspect-h-7 lg:aspect-none'>
                    <img
                      className='rounded-lg shadow-lg object-cover object-center'
                      src={homeCleaningImg}
                      alt='A living room with gray couch and bamboo coffee table'
                      width={877}
                      height={1019}
                    />
                  </div>
                  <figcaption className='mt-3 flex text-sm text-gray-500'>
                    <CameraIcon className='flex-none w-5 h-5 text-gray-400' aria-hidden='true' />
                    <span className='ml-2'>Photograph by Minh Pham</span>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className='mt-8 lg:mt-0'>
              <div className='text-base max-w-prose mx-auto lg:max-w-none'>
                <p className='text-lg text-gray-500'>
                  Come home to a clean and refreshing home after a long day at work. Our cleaning staff will take care of your home so you don’t have to lift a finger.
                </p>
              </div>
              <div className='mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1'>
                <p>
                  Our personalized cleaning services are designed around your priorities.

                </p>
                <p>
                  Whether you’re looking for a one-time deep cleaning or you're looking for recurring cleanings to make sure you always return to a clean home, we are happy to create a cleaning plan that works for you.
                </p>
                <p>
                  Our team provides a wide array of services including
                </p>
                <ul>
                  <li>Dusting</li>
                  <li>Mopping</li>
                  <li>Vacuuming</li>
                  <li>Window cleanings</li>
                  <li>Detailed cleanings </li>
                </ul>
                <h3>Solutions for Every Inch of Your Home</h3>
                <p>
                  Regardless of what you’re looking for, our cleaners will come fully prepared with the right equipment
                  and the right experience to know what will work for your home and what won't. We’ll provide each and every room the attention it deserves.
                </p>
                <p>
                  We offer weekly, bi-weekly, and monthly cleaning services too to make sure your home is always as clean as you want it to be.
                  Most of our clients end up asking us to come in regularly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line */}
      <CTA />
      <Footer />
    </>
  )
}
export default HomeCleaning
