
import Footer from './Footer'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import {
  MenuIcon,
  NewspaperIcon,
  PhoneIcon,
  HomeIcon,
  OfficeBuildingIcon,
  QuestionMarkCircleIcon,
  XIcon
} from '@heroicons/react/outline'

import whiteQintiLogo from '../assets/white-qinti-logo.svg'
import qintiLogo from '../assets/qinti-logo.svg'
import CTA from './landing/CTA'
import contactImg from '../assets/contact.jpg'

const services = [
  {
    name: 'Home Cleaning',
    description: 'Come home to a clean and refreshing home after a long day of work.',
    href: '/home-cleaning',
    icon: HomeIcon
  },
  {
    name: 'Office Cleaning',
    description: 'Ensure a clean and productive environment for your office.',
    href: '/office-cleaning',
    icon: OfficeBuildingIcon
  }
]
const mobileMenu = [
  {
    name: 'Home Cleaning',
    href: '/home-cleaning',
    icon: HomeIcon
  },
  {
    name: 'Office Cleaning',
    href: '/office-cleaning',
    icon: OfficeBuildingIcon
  },
  {
    name: 'About Us',
    href: '/about-us',
    icon: NewspaperIcon
  },
  {
    name: 'FAQ',
    href: '/faq',
    icon: QuestionMarkCircleIcon
  },
  {
    name: 'Contact',
    href: '/contact',
    icon: PhoneIcon
  }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const contactDetails = [
  { name: 'Say hello', email: 'qinticleaning@gmail.com', telephone: '+1 (310) 941-6100' },
  { name: 'Hours', email: 'M-F: 8:00 AM - 6:00 PM', telephone: 'Saturday: 10:00 AM - 5:00 PM' },
  { name: 'Service area', email: 'Marina del Rey, Playa del Rey, Manhattan Beach, Santa Monica, Venice, Redondo Beach and surrounding areas!', telephone: '' }
]
const locations = [
  { city: 'Los Angeles', address: ['Marina del Rey, CA 90292'] },
  { city: 'San Francisco', address: ['Coming soon'] }
]

function Contact() {
  return (
    <>
      <header className='relative pb-24 bg-light-blue-800 sm:pb-32'>
        <div className='absolute inset-0'>
          <img
            className='w-full h-full object-cover'
            src={contactImg}
            alt='A backview of a man on high phone making a phone call'
          />
          <div
            className='absolute inset-0 bg-gradient-to-l from-light-blue-800 to-yale mix-blend-multiply'
            aria-hidden='true'
          />
        </div>
        <Popover as='div' className='relative z-10'>
          {({ open }) => (
            <>
              <div className='flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10'>
                <div>
                  <a href='/' className='flex'>
                    <span className='sr-only'>Workflow</span>
                    <img
                      className='h-12 w-auto sm:h-12'
                      src={whiteQintiLogo}
                      alt='an all white version of the Qinti Cleaning logo'
                    />
                  </a>
                </div>
                <div className='-mr-2 -my-2 md:hidden'>
                  <Popover.Button className='rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-transparent '>
                    <span className='sr-only'>Open menu</span>
                    <MenuIcon className='h-6 w-6' aria-hidden='true' />
                  </Popover.Button>
                </div>
                <div className='hidden md:flex-1 md:flex md:items-center md:justify-between'>
                  <Popover.Group as='nav' className='flex space-x-10'>
                    <a
                      href='/about-us' className='text-base font-medium text-white hover:text-gray-300'
                    >
                      About Us
                    </a>
                    <Popover className='relative'>
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? 'text-gray-400' : 'text-gray-100',
                              'group rounded-md inline-flex items-center text-base font-medium hover:text-gray-400 focus:outline-none focus:ring-transparent focus:ring-offset-2 focus:ring-yale'
                            )}
                          >
                            <span>Services</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? 'text-white' : 'text-white',
                                'ml-2 h-5 w-5 group-hover:text-gray-500'
                              )}
                              aria-hidden='true'
                            />
                          </Popover.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            enter='transition ease-out duration-200'
                            enterFrom='opacity-0 translate-y-1'
                            enterTo='opacity-100 translate-y-0'
                            leave='transition ease-in duration-150'
                            leaveFrom='opacity-100 translate-y-0'
                            leaveTo='opacity-0 translate-y-1'
                          >
                            <Popover.Panel
                              static
                              className='absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-3xl'
                            >
                              <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                <div className='relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2'>
                                  {services.map((item) => (
                                    <a
                                      key={item.name}
                                      href={item.href}
                                      className='-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50'
                                    >
                                      <div className='flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-yale text-white sm:h-12 sm:w-12'>
                                        <item.icon className='h-6 w-6' aria-hidden='true' />
                                      </div>
                                      <div className='ml-4'>
                                        <p className='text-base font-medium text-gray-900'>{item.name}</p>
                                        <p className='mt-1 text-sm text-gray-500'>{item.description}</p>
                                      </div>
                                    </a>
                                  ))}
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                    <a href='/faq' className='text-base font-medium text-gray-100 hover:text-gray-400'>
                      FAQ
                    </a>
                    <a href='/contact' className='text-base font-medium text-gray-100 hover:text-gray-400'>
                      Contact
                    </a>

                  </Popover.Group>
                </div>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter='duration-200 ease-out'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='duration-100 ease-in'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Popover.Panel
                  focus
                  static
                  className='absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden'
                >
                  <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50'>
                    <div className='pt-5 pb-6 px-5'>
                      <div className='flex items-center justify-between'>
                        <div>
                          <img
                            className='h-8 w-auto'
                            src={qintiLogo}
                            alt='The qinti logo, the word qinti beside a hummingbird'
                          />
                        </div>
                        <div className='-mr-2'>
                          <Popover.Button className='bg-transparent rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-transparent'>
                            <span className='sr-only'>Close menu</span>
                            <XIcon className='h-6 w-6' aria-hidden='true' />
                          </Popover.Button>
                        </div>
                      </div>
                      <div className='mt-6'>
                        <nav className='grid gap-6'>
                          {mobileMenu.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className='-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50'
                            >
                              <div className='flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-yale text-white'>
                                <item.icon className='h-6 w-6' aria-hidden='true' />
                              </div>
                              <div className='ml-4 text-base font-medium text-gray-900'>{item.name}</div>
                            </a>
                          ))}

                        </nav>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>

        <div className='relative mt-24 max-w-md mx-auto px-4 sm:max-w-3xl sm:mt-32 sm:px-6 lg:max-w-7xl lg:px-8'>
          <h1 className='text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl'>Get in touch</h1>
          <p className='mt-6 text-xl text-blue-100 max-w-3xl'>
            Make your life easier and get in touch today!
          </p>
        </div>
      </header>
      <main>
        <div className='bg-white'>
          <div className='max-w-md mx-auto py-24 px-4 sm:max-w-3xl sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8'>
            <div className='divide-y divide-warm-gray-200'>
              <section className='lg:grid lg:grid-cols-3 lg:gap-8' aria-labelledby='contactHeading'>
                <h2 id='contactHeading' className='text-2xl font-extrabold text-warm-gray-900 sm:text-3xl'>
                  Contact Info
                </h2>
                <div className='mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2'>
                  {contactDetails.map((item) => (
                    <div key={item.name}>
                      <h3 className='text-lg font-medium text-warm-gray-900'>{item.name}</h3>
                      <dl className='mt-2 text-base text-warm-gray-500'>
                        <div>
                          <dt className='sr-only'>Email</dt>
                          <dd>{item.email}</dd>
                        </div>
                        <div className='mt-1'>
                          <dt className='sr-only'>Phone number</dt>
                          <dd>{item.telephone}</dd>
                        </div>
                      </dl>
                    </div>
                  ))}
                </div>
              </section>
              <section className='mt-16 pt-16 lg:grid lg:grid-cols-3 lg:gap-8' aria-labelledby='locationHeading'>
                <h2 id='locationHeading' className='text-2xl font-extrabold text-warm-gray-900 sm:text-3xl'>
                  Locations
                </h2>
                <div className='mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2'>
                  {locations.map((location) => (
                    <div key={location.city}>
                      <h3 className='text-lg font-medium text-warm-gray-900'>{location.city}</h3>
                      <div className='mt-2 text-base text-warm-gray-500 space-y-1'>
                        {location.address.map((line) => (
                          <p key={line}>{line}</p>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </div>
        </div>
        {/* eslint-disable-next-line */}
        <CTA />
      </main>
      <Footer />
    </>
  )
}
export default Contact
