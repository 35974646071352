import Header from './landing/Header'
import Footer from './Footer'
import CTA from './landing/CTA'

import aboutImg from '../assets/about.jpg'

function About () {
  return (
    <>
      <Header />
      <div className='relative py-16 bg-white overflow-hidden'>
        <div className='hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full'>
          <div className='relative h-full text-lg max-w-prose mx-auto' aria-hidden='true'>
            <svg
              className='absolute top-12 left-full transform translate-x-32'
              width={404}
              height={384}
              fill='none'
              viewBox='0 0 404 384'
            >
              <defs>
                <pattern
                  id='74b3fd99-0a6f-4271-bef2-e80eeafdf357'
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits='userSpaceOnUse'
                >
                  <rect x={0} y={0} width={4} height={4} className='text-gray-200' fill='currentColor' />
                </pattern>
              </defs>
              <rect width={404} height={384} fill='url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)' />
            </svg>
            <svg
              className='absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32'
              width={404}
              height={384}
              fill='none'
              viewBox='0 0 404 384'
            >
              <defs>
                <pattern
                  id='f210dbf6-a58d-4871-961e-36d5016a0f49'
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits='userSpaceOnUse'
                >
                  <rect x={0} y={0} width={4} height={4} className='text-gray-200' fill='currentColor' />
                </pattern>
              </defs>
              <rect width={404} height={384} fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)' />
            </svg>
            <svg
              className='absolute bottom-12 left-full transform translate-x-32'
              width={404}
              height={384}
              fill='none'
              viewBox='0 0 404 384'
            >
              <defs>
                <pattern
                  id='d3eb07ae-5182-43e6-857d-35c643af9034'
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits='userSpaceOnUse'
                >
                  <rect x={0} y={0} width={4} height={4} className='text-gray-200' fill='currentColor' />
                </pattern>
              </defs>
              <rect width={404} height={384} fill='url(#d3eb07ae-5182-43e6-857d-35c643af9034)' />
            </svg>
          </div>
        </div>
        <div className='relative px-4 sm:px-6 lg:px-8'>
          <div className='text-lg max-w-prose mx-auto'>
            <h1>
              <span className='block text-base text-center text-yale font-semibold tracking-wide uppercase'>
                Welcome
              </span>
              <span className='mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
                About Us
              </span>
            </h1>
          </div>
          <div className='mt-6 prose prose-yale prose-lg text-gray-500 mx-auto'>
            <h2>Our Founding Story</h2>
            <p>
              In 2008, Olga Velezmoro was working in the home cleaning industry when she noticed just how much large cleaning companies were overcharging customers and underpaying their cleaners. Soon after, she quit her job and started her own business, Qinti Cleaning, with the mission of compensating her cleaners fairly while providing high-quality and affordable cleaning services to busy people in Los Angeles. Though finding customers as a new business was difficult, we quickly gained a reputation for superior quality cleaning and amazing customer service and our customers started telling their friends about us!
            </p>
            <figure>
              <img
                className='w-full rounded-lg'
                src={aboutImg}
                alt='The Los Angeles skyline at sunset.'
                width={1310}
                height={873}
              />
              <figcaption>The Los Angeles skyline at sunset.</figcaption>
            </figure>
            <h2>How We Hire</h2>
            <p>
              We hire only the trusted and professional cleaners to work for Qinti. Trusted means most of our new cleaners
              are hired through referrals. It also means we background check and insure them before letting visit your
              home. Professional means we only hire cleaners with at least a year of full-time experience and
              cleanrs who pass our rigerous training period where we assure they can uphold our cleaning standards.
            </p>
            <h2>Where We're Headed!</h2>
            <p>
              Even though 2020 was a difficult year, Qinti Cleaning in 2021 is already growing quickly!
              We're experimenting with new technology and improving the way we clean to allow us to scale while preserving the
              quality and customer service that’s allowed us to get to where we are today.
            </p>
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line */}
      <CTA />
      <Footer />
    </>
  )
}
export default About
