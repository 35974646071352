import React, { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
  NewspaperIcon,
  PhoneIcon,
  HomeIcon,
  OfficeBuildingIcon,
  QuestionMarkCircleIcon,
  XIcon
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import heroImg from '../../assets/hero-img.jpg'
import qintiLogo from '../../assets/qinti-logo.svg'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import BookingModal from '../BookingModal'

const services = [
  {
    name: 'Home Cleaning',
    description: 'Come home to a clean and refreshing home after a long day of work.',
    href: '/home-cleaning',
    icon: HomeIcon
  },
  {
    name: 'Office Cleaning',
    description: 'Ensure a clean and productive environment for your office.',
    href: '/office-cleaning',
    icon: OfficeBuildingIcon
  }
]
const mobileMenu = [
  {
    name: 'Home Cleaning',
    href: '/home-cleaning',
    icon: HomeIcon
  },
  {
    name: 'Office Cleaning',
    href: '/office-cleaning',
    icon: OfficeBuildingIcon
  },
  {
    name: 'About Us',
    href: '/about-us',
    icon: NewspaperIcon
  },
  {
    name: 'FAQ',
    href: '/faq',
    icon: QuestionMarkCircleIcon
  },
  {
    name: 'Contact',
    href: '/contact',
    icon: PhoneIcon
  }
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

function Hero () {
  return (
    <div className='relative bg-white overflow-hidden'>
      <div className='max-w-7xl mx-auto'>
        <div className='relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32'>
          <svg
            className='hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2'
            fill='currentColor'
            viewBox='0 0 100 100'
            preserveAspectRatio='none'
            aria-hidden='true'
          >
            <polygon points='50,0 100,0 50,100 0,100' />
          </svg>

          <Popover className='relative bg-white'>
            {({ open }) => (
              <>
                <div className='flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10'>
                  <div>
                    <a href='/' className='flex'>
                      <span className='sr-only'>Workflow</span>
                      <img
                        className='h-12 w-auto sm:h-12'
                        src={qintiLogo}
                        alt='the qinti logo: the word qinti in blue beside a hummingbird in light blue'
                      />
                    </a>
                  </div>
                  <div className='-mr-2 -my-2 md:hidden'>
                    <Popover.Button className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-transparent focus:ring-transparent focus:ring-transparent'>
                      <span className='sr-only'>Open menu</span>
                      <MenuIcon className='h-6 w-6' aria-hidden='true' />
                    </Popover.Button>
                  </div>
                  <div className='hidden md:flex-1 md:flex md:items-center md:justify-between'>
                    <Popover.Group as='nav' className='flex space-x-10'>
                      <a href='/about-us' className='text-base font-medium text-gray-500 hover:text-gray-900'>
                        About Us
                      </a>
                      <Popover className='relative'>
                        {({ open }) => (
                          <>
                            <Popover.Button
                              className={classNames(
                                open ? 'text-gray-900' : 'text-gray-500',
                                'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-transparent'
                              )}
                            >
                              <span>Services</span>
                              <ChevronDownIcon
                                className={classNames(
                                  open ? 'text-gray-600' : 'text-gray-400',
                                  'ml-2 h-5 w-5 group-hover:text-gray-500'
                                )}
                                aria-hidden='true'
                              />
                            </Popover.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter='transition ease-out duration-200'
                              enterFrom='opacity-0 translate-y-1'
                              enterTo='opacity-100 translate-y-0'
                              leave='transition ease-in duration-150'
                              leaveFrom='opacity-100 translate-y-0'
                              leaveTo='opacity-0 translate-y-1'
                            >
                              <Popover.Panel
                                static
                                className='absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-3xl'
                              >
                                <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                  <div className='relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2'>
                                    {services.map((item) => (
                                      <a
                                        key={item.name}
                                        href={item.href}
                                        className='-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50'
                                      >
                                        <div className='flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-yale text-white sm:h-12 sm:w-12'>
                                          <item.icon className='h-6 w-6' aria-hidden='true' />
                                        </div>
                                        <div className='ml-4'>
                                          <p className='text-base font-medium text-gray-900'>{item.name}</p>
                                          <p className='mt-1 text-sm text-gray-500'>{item.description}</p>
                                        </div>
                                      </a>
                                    ))}
                                  </div>
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                      <a href='/faq' className='text-base font-medium text-gray-500 hover:text-gray-900'>
                        FAQ
                      </a>
                      <a href='/contact' className='text-base font-medium text-gray-500 hover:text-gray-900'>
                        Contact
                      </a>

                    </Popover.Group>
                  </div>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter='duration-200 ease-out'
                  enterFrom='opacity-0 scale-95'
                  enterTo='opacity-100 scale-100'
                  leave='duration-100 ease-in'
                  leaveFrom='opacity-100 scale-100'
                  leaveTo='opacity-0 scale-95'
                >
                  <Popover.Panel
                    focus
                    static
                    className='absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden'
                  >
                    <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50'>
                      <div className='pt-5 pb-6 px-5'>
                        <div className='flex items-center justify-between'>
                          <div>
                            <img
                              className='h-8 w-auto'
                              src={qintiLogo}
                              alt='the qinti logo: the word qinti in blue beside a hummingbird in light blue'
                            />
                          </div>
                          <div className='-mr-2'>
                            <Popover.Button className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-transparent focus:ring-inset focus:ring-transparent'>
                              <span className='sr-only'>Close menu</span>
                              <XIcon className='h-6 w-6' aria-hidden='true' />
                            </Popover.Button>
                          </div>
                        </div>
                        <div className='mt-6'>
                          <nav className='grid gap-6'>
                            {mobileMenu.map((item) => (
                              <a
                                key={item.name}
                                href={item.href}
                                className='-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50'
                              >
                                <div className='flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-yale text-white'>
                                  <item.icon className='h-6 w-6' aria-hidden='true' />
                                </div>
                                <div className='ml-4 text-base font-medium text-gray-900'>{item.name}</div>
                              </a>
                            ))}

                          </nav>
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <main className='mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28'>
            <div className='sm:text-center lg:text-left'>
              <h1 className='text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl'>
                <span className='block xl:inline'>We help your home</span>{' '}
                <span className='block text-yale xl:inline'>
                  always look its best.
                </span>
              </h1>
              <p className='mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0'>
                A home cleaning service designed to fit your needs.
              </p>
              <div className='mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start'>
                <div className='rounded-md shadow'>
                  <BookingModal />
                </div>
                <div className='mt-3 sm:mt-0 sm:ml-3'>
                  <AnchorLink
                    href='#stats'
                    className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-900 bg-blue-100 hover:bg-blue-200 md:py-4 md:text-lg md:px-10'
                  >
                    Learn More
                  </AnchorLink>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className='lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2'>
        <img
          className='h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full'
          src={heroImg}
          alt='a clean kitchen with stainless steel appliances, white woodwork, and hardwood floors.'
        />
      </div>
    </div>
  )
}
export default Hero
