import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ModalFooter from './ModalFooter'
import ModalContent from './ModalContent'
import { PhoneIncomingIcon, HomeIcon, SparklesIcon, CalendarIcon } from '@heroicons/react/outline'
import Contact from './Contact'
import Home from './Home'
import Service from './Service'
import Availability from './Availability'

function Modal ({
  req,
  setReq,
  originalReq,
  isOpen,
  setIsOpen,
  current,
  setCurrent
}) {
  const steps = [
    {
      icon: PhoneIncomingIcon,
      title: 'How do we get in touch?',
      content: <Contact req={req} setReq={setReq} />
    },
    {
      icon: HomeIcon,
      title: 'Tell us a bit about your home.',
      content: <Home req={req} setReq={setReq} />
    },
    {
      icon: SparklesIcon,
      title: 'What type of cleaning do you need?',
      content: <Service req={req} setReq={setReq} />
    },
    {
      icon: CalendarIcon,
      title: 'When are you available?',
      content: <Availability req={req} setReq={setReq} />
    }
  ]
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-10 inset-0 overflow-y-auto'
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
          setCurrent(0)
        }}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
              <ModalContent current={current} steps={steps} />
              <ModalFooter originalReq={originalReq} req={req} setReq={setReq} setIsOpen={setIsOpen} setCurrent={setCurrent} current={current} />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal
