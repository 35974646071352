import React from 'react'
import { Dialog } from '@headlessui/react'
import { CheckCircleIcon, BanIcon } from '@heroicons/react/outline'

const SUCCESS_CODE = 4
const FAILURE_CODE = 5

const SucessModal = () => {
  return (
    <div className='flex flex-col items-center bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
      <div className='mx-auto mt-3 flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10'>
        <CheckCircleIcon className='h-6 w-6 text-green-900 bg-green-100' aria-hidden='true' />
      </div>
      <div className='sm:flex sm:items-start'>
        <div className='text-center sm:mt-0 sm:ml-4 sm:text-left'>
          <Dialog.Title as='h3' className='mt-2 text-lg leading-6 font-medium text-gray-900'>
            We Got Your Request!
          </Dialog.Title>
        </div>
      </div>
      <div className='mt-2 text-sm text-center'>
        Congrats! You're one step closer to a clean home.
      </div>
      <div className='text-sm text-center'>
        You can expect a text from us very soon.
      </div>
    </div>

  )
}

const FailureModal = () => {
  return (
    <div className='flex flex-col items-center bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
      <div className='mx-auto mt-3 flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
        <BanIcon className='h-6 w-6 text-red-900 bg-red-100' aria-hidden='true' />
      </div>
      <div className='sm:flex sm:items-start'>
        <div className='text-center sm:mt-0 sm:ml-4 sm:text-left'>
          <Dialog.Title as='h3' className='mt-2 text-lg leading-6 font-medium text-gray-900'>
            Hmmm... Something Went Wrong!
          </Dialog.Title>
        </div>
      </div>
      <div className='mt-2 text-sm text-center'>
        Try again submitting again. If that doesn't work,
      </div>
      <div className='text-sm text-center'>
        shoot us an email at <b>contact@qinticleaning.com.</b>
      </div>
    </div>

  )
}

function ModalContent ({ current, steps }) {
  if (current === SUCCESS_CODE) {
    return <SucessModal />
  } else if (current === FAILURE_CODE) {
    return <FailureModal />
  } else {
    return (
      <>
        {steps.map((step, i) => (
          <div
            key={step.title}
            className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'
            style={{ display: i === current ? 'block' : 'none' }}
          >
            <div className='sm:flex sm:items-start'>
              <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10'>
                {React.createElement(step.icon, { className: 'h-6 w-6 text-blue-900 bg-blue-100', 'aria-hidden': 'true' }, null)}
              </div>
              <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
                  {step.title}
                </Dialog.Title>
                <div className='mt-2'>
                  {step.content}
                </div>
              </div>
            </div>
          </div>
        ))}

      </>
    )
  }
}

export default ModalContent
