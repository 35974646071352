import React from 'react'

export default function Availability ({ req, setReq }) {
  const handleChange = (e, field) => {
    setReq(req => {
      const newReq = JSON.parse(JSON.stringify(req))
      newReq[field] = e.target.value
      return newReq
    })
  }
  return (
    <div>
      <p className='mt-5 text-sm text-gray-500'>
        Tell us briefly a few different days and time-windows you're available for a cleaning.
      </p>
      <div className='mt-1'>
        <textarea
          onChange={(e) => handleChange(e, 'availability')}
          value={req.availability}
          id='about'
          name='about'
          rows={3}
          className='placeholder-gray-300 shadow-sm focus:ring-alice focus:border-alice mt-1 block w-full sm:text-sm border-gray-300 rounded-md'
          placeholder="E.g. I'm available this Monday: 3pm-5pm, Tuesday morning, and Friday: after 1pm!"
        />
      </div>

    </div>
  )
}
