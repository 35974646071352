import React, { useState } from 'react'
import axios from '../../utils/axios'

const TOTAL_STEPS = 3 // starting at 0
const SUCCESS_CODE = 4
const FAILURE_CODE = 5

function ModalFooter ({ req, setReq, setIsOpen, originalReq, setCurrent, current }) {
  const [isLoading, setIsLoading] = useState(false)
  const disabledNext = req.contact.phone.length < 14
  return (
    <div className={`bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse ${current > TOTAL_STEPS ? 'justify-center' : ''}`}>
      {(current < TOTAL_STEPS) &&
         (
           <button
             type='button'
             disabled={disabledNext}
             className='disabled:opacity-50 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yale text-base font-medium text-white hover:bg-dark-yale focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-yale sm:ml-3 sm:w-auto sm:text-sm'
             onClick={() => setCurrent(current => current + 1)}
           >
             Next
           </button>
         )}
      {(current === TOTAL_STEPS) && (
        <button
          type='button'
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yale text-base font-medium text-white hover:bg-dark-yale focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-yale sm:ml-3 sm:w-auto sm:text-sm'
          onClick={() => {
            setIsLoading(true)
            axios.post('/api/web', req)
              .then(res => {
                setCurrent(SUCCESS_CODE)
                console.log(res)
              })
              .catch(err => {
                setCurrent(FAILURE_CODE)
                console.error(err)
              })
              .finally(() => {
                setIsLoading(false)
                setReq(req => JSON.parse(JSON.stringify(originalReq)))
              })
          }}
        >
          {
          isLoading &&
            (
              <svg class='animate-spin -ml-1 mr-3 h-5 w-5 text-white' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
                <circle class='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' stroke-width='4' />
                <path class='opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z' />
              </svg>
            )
          }
          Finish
        </button>
      )}
      {(current > 0 && current <= TOTAL_STEPS) && (
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-yale sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={() => setCurrent(current => current - 1)}
        >
          Back
        </button>
      )}
      {(current === SUCCESS_CODE || current === FAILURE_CODE) && (
        <button
          type='button' // mt-2 border-transparent font-medium rounded-md text-blue-900 bg-blue-100 hover:bg-blue-200
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-blue-900 bg-blue-100 hover:bg-blue-200 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-yale sm:ml-3 sm:w-auto sm:text-sm'
          onClick={() => {
            setCurrent(0)
            setIsOpen(false)
          }}
        >
          Return to Homepage
        </button>
      )}
    </div>
  )
}

export default ModalFooter
