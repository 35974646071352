import React from 'react'
import contentImg from '../../assets/content-img.jpg'

function Content () {
  return (
    <div id='our-process' className='relative bg-white'>
      <div className='lg:absolute lg:inset-0'>
        <div className='lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2'>
          <img
            className='h-56 w-full object-cover lg:absolute lg:h-full'
            src={contentImg}
            alt='A wooden kitchen counter with a spray bottle hanging on a metal rack and a white bowl holding two apples'
          />
        </div>
      </div>
      <div className='relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2'>
        <div className='lg:col-start-2 lg:pl-8'>
          <div className='text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0'>
            <h2 className='leading-6 text-yale font-semibold tracking-wide uppercase'>Work with us</h2>
            <h3 className='mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
              Our Process
            </h3>
            <p className='mt-8 text-lg text-gray-500'>
              Click the <b>Book Now</b> button above and fill out our quick form: we ask some basic questions about your home and how to best reach you. You can expect a reply from us same-day.
            </p>
            <div className='mt-5 prose prose-indigo text-gray-500'>
              <p>
                We get back to you with a price estimate and confirm the time of your appointment. <b>And you're done!</b> We take care of the rest—sending a punctual, professional team of cleaners your way with everything they need.
              </p>
              <p>
                If you're happy with our service, you could leave us some feedback or schedule us for recurring services!
              </p>
              <p>
                In the rare event you're not satisfied with the quality of our service, we will make it right with our <b>Happiness Guarantee.</b>
              </p>
              <p>
                We've built our reputation on trust. Trust that you’re dealing with people who care genuinely about your home, and trust that if things go wrong, you’ll be able to talk with <b>a customer service team that’s left thousands of customers happy before.</b>
              </p>
              <h3>How We’re Different</h3>
              <p>
                We’re a small business <b>empowered by new technology</b>. We use software in key points of our service to communicate with you efficiently, make sure our cleaners get to your home on-time and always leave your home exactly how you want it.
              </p>
              <p>
                What’s always set us apart, however, is <b>our commitment to real customer service.</b> We’re responsive and accommodating to any of your requests. After the cleaning, especially if you’re a new client, we’ll always call to make sure you’re pleased with our services.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Content
