import React, { Fragment, useState } from 'react'
import Modal from './booking/Modal'

const request = {
  contact: {
    first_name: '',
    last_name: '',
    phone: '',
    zip: ''
  },
  home: {
    bedrooms: 'Studio',
    bathrooms: '1',
    size: 'Under 1,000',
    pets: 'None',
    floors: {
      hardwood: false,
      laminate: false,
      tile: false,
      carpet: false,
      marbel: false,
      other: false
    }
  },
  service: {
    type: 'Standard Cleaning',
    extras: {
      oven: false,
      fridge: false,
      windows: false
    }
  },
  availability: ''
}

function BookingModal () {
  const [current, setCurrent] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [req, setReq] = useState(JSON.parse(JSON.stringify(request)))

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-yale hover:bg-dark-yale md:py-4 md:text-lg md:px-10'
      >
        Book Now
      </button>
      {isOpen && (
        <Modal
          req={req}
          setReq={setReq}
          originalReq={request}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          current={current}
          setCurrent={setCurrent}
        />
      )}
    </>
  )
}

export default BookingModal
