import React from 'react'
import './Home.css'

const Floors = ({ req, setReq }) => {
  const handleChange = (e) => {
    const { checked: isChecked, name } = e.target
    setReq(oldReq => {
      const newReq = JSON.parse(JSON.stringify(oldReq))
      newReq.home.floors[name] = isChecked
      return newReq
    })
  }
  return (
    <fieldset>
      <legend className='text-base font-medium text-gray-900'>Types of Floors</legend>
      <div className='grid grid-cols-9 gap-6'>
        <div className='col-start-3 col-span-3 sm:col-span-5'>
          <div className='mt-4 space-y-4'>
            <div className='flex items-start'>
              <div className='flex items-center h-5'>
                <input
                  onChange={(e) => handleChange(e)}
                  checked={req.home.floors.hardwood}
                  id='hardwood'
                  name='hardwood'
                  type='checkbox'
                  className='focus:ring-transparent h-4 w-4 text-yale border-gray-300 rounded'
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='hardwood' className='font-medium text-gray-700'>
                  Hardwood
                </label>
              </div>
            </div>
            <div className='flex items-start'>
              <div className='flex items-center h-5'>
                <input
                  onChange={(e) => handleChange(e)}
                  checked={req.home.floors.laminate}
                  id='laminate'
                  name='laminate'
                  type='checkbox'
                  className='focus:ring-transparent h-4 w-4 text-yale border-gray-300 rounded'
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='laminate' className='font-medium text-gray-700'>
                  Laminate
                </label>
              </div>
            </div>
            <div className='flex items-start'>
              <div className='flex items-center h-5'>
                <input
                  onChange={(e) => handleChange(e)}
                  checked={req.home.floors.tile}
                  id='tile'
                  name='tile'
                  type='checkbox'
                  className='focus:ring-transparent h-4 w-4 text-yale border-gray-300 rounded'
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='tile' className='font-medium text-gray-700'>
                  Tile
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='col-span-1 sm:col-span-1'>
          <div className='mt-4 space-y-4'>
            <div className='flex items-start'>
              <div className='flex items-center h-5'>
                <input
                  onChange={(e) => handleChange(e)}
                  checked={req.home.floors.carpet}
                  id='carpet'
                  name='carpet'
                  type='checkbox'
                  className='focus:ring-transparent h-4 w-4 text-yale border-gray-300 rounded'
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='carpet' className='font-medium text-gray-700'>
                  Carpet
                </label>
              </div>
            </div>
            <div className='flex items-start'>
              <div className='flex items-center h-5'>
                <input
                  onChange={(e) => handleChange(e)}
                  checked={req.home.floors.marbel}
                  id='marbel'
                  name='marbel'
                  type='checkbox'
                  className='focus:ring-transparent h-4 w-4 text-yale border-gray-300 rounded'
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='marbel' className='font-medium text-gray-700'>
                  Marbel
                </label>
              </div>
            </div>
            <div className='flex items-start'>
              <div className='flex items-center h-5'>
                <input
                  onChange={(e) => handleChange(e)}
                  checked={req.home.floors.other}
                  id='other'
                  name='other'
                  type='checkbox'
                  className='focus:ring-transparent h-4 w-4 text-yale border-gray-300 rounded'
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='other' className='font-medium text-gray-700'>
                  Other
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  )
}
export default function Home ({ req, setReq }) {
  const handleSelect = (e, field) => {
    const { value } = e.target
    setReq(req => {
      const newReq = JSON.parse(JSON.stringify(req))
      newReq.home[field] = value
      return newReq
    })
  }
  return (
    <form action='#' method='POST'>
      <div className='overflow-hidden sm:rounded-md'>
        <div className='sm:p-0'>
          <div className='grid grid-cols-6 gap-6'>
            <div className='col-span-3 sm:col-span-3'>
              <label htmlFor='bedrooms' className='block text-sm font-medium text-gray-700'>
                Bedrooms
              </label>
              <select
                onChange={e => handleSelect(e, 'bedrooms')}
                value={req.home.bedrooms}
                id='bedrooms'
                name='bedrooms'
                autoComplete='bedrooms'
                className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-transparent focus:border-alice sm:text-sm'
              >
                <option value='Studio'>Studio</option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
              </select>
            </div>
            <div className='col-span-3 sm:col-span-3'>
              <label htmlFor='bathrooms' className='block text-sm font-medium text-gray-700'>
                Bathrooms
              </label>
              <select
                onChange={e => handleSelect(e, 'bathrooms')}
                value={req.home.bathrooms}
                id='bathrooms'
                name='bathrooms'
                autoComplete='bathrooms'
                className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-transparent focus:border-alice sm:text-sm'
              >
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
              </select>
            </div>
            <div className='col-span-3 sm:col-span-3'>
              <label htmlFor='square_feet' className='block text-sm font-medium text-gray-700'>
                Total Square Feet
              </label>
              <select
                onChange={e => handleSelect(e, 'size')}
                value={req.home.size}
                name='square_feet'
                id='square_feet'
                autoComplete='given-name'
                className='mt-1 focus:ring-transparent focus:border-alice block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              >
                <option value='Under 1,000'>Under 1,000</option>
                <option value='1,000 - 1,500'>1,000 - 1,500</option>
                <option value='1,500 - 2,000'>1,500 - 2,000</option>
                <option value='2,000 - 2,500'>2,000 - 2,500</option>
                <option value='2,500 - 3,000'>2,500 - 3,000</option>
                <option value='3,000 - 3,500'>3,000 - 3,500</option>
                <option value='3,500 - 4,000'>3,500 - 4,000</option>
                <option value='Over 4,000'>Over 4,000</option>
              </select>
            </div>
            <div className='col-span-3 sm:col-span-3'>
              <label htmlFor='pets' className='block text-sm font-medium text-gray-700'>
                Pets
              </label>
              <select
                onChange={e => handleSelect(e, 'pets')}
                value={req.home.pets}
                id='pets'
                name='pets'
                autoComplete='pets'
                className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-transparent focus:border-alice sm:text-sm'
              >
                <option value='None'>None</option>
                <option value='Dogs'>Dogs</option>
                <option value='Cats'>Cats</option>
                <option value='Dogs and Cats'>Dogs and Cats</option>
              </select>
            </div>
            <div className='col-span-6 sm:col-span-6'>
              <Floors req={req} setReq={setReq} />
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
