import Header from './landing/Header'
import Footer from './Footer'
import CTA from './landing/CTA'

import officeCleaningImg from '../assets/office-cleaning.jpg'
function OfficeCleaning () {
  return (
    <>
      <Header />
      <div className='bg-gray-800'>
        <div className='max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex lg:justify-between'>
          <div className='max-w-xl'>
            <h2 className='text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl'>
              Office Cleaning
            </h2>
            <p className='mt-5 text-xl text-gray-400'>
              Keep your business space spotless and your employees happy.
            </p>
          </div>
        </div>
      </div>
      <div className='relative bg-white'>
        <div className='lg:absolute lg:inset-0'>
          <div className='lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2'>
            <img
              className='h-56 w-full object-cover lg:absolute lg:h-full'
              src={officeCleaningImg}
              alt='An office with orange and blue furniture and tall windows'
            />
          </div>
        </div>
        <div className='relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2'>
          <div className='lg:col-start-2 lg:pl-8'>
            <div className='text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0'>
              <h2 className='leading-6 text-yale font-semibold tracking-wide uppercase'>Work with us</h2>
              <h3 className='mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
                Our Process
              </h3>
              <p className='mt-8 text-lg text-gray-500'>
                As a business owner, you have a lot on your to-do list. Let us take care of the dirty work. Qinti Cleaning offers cleaning services for
                offices, stores, salons, gyms, and other businesses to give you back the time
                to focus what's most critical to your business.
              </p>
              <div className='mt-5 prose prose-yale text-gray-500'>
                <p>
                  We’re open to working with any business, here’s a list of just a few different types of businesses
                  we’re experienced with cleaning:
                </p>
                <ul>
                  <li>Shipping warehouses</li>
                  <li>Software company offices</li>
                  <li>Common space areas for apartment and condo complexes</li>
                  <li>Hair salons</li>
                  <li>Boutique clothing stores</li>
                  <li>Gyms</li>
                </ul>
                <p>
                  For over a decade, the Qinti Cleaning name has been trusted by homeowners for reliable
                  high-quality cleaning services.
                </p>
                <p>We bring that same quality of service to your business.</p>
                <p>
                  A fresh business space emphasizes professionalism and creates a healthy work atmosphere for your employees.
                </p>
                <p>
                  Whether your space is large or small, we can transform your dirty office into an immaculate space using our
                  industry-standard cleaning methods and products.
                </p>
                <p>Send us an email at <b>contact@qinticleaning.com</b> with the name and square footage of your business and we'll respond promptly!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line */}
      <CTA />
      <Footer />
    </>
  )
}
export default OfficeCleaning
