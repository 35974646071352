import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import Landing from './components/Landing'
import About from './components/About'
import Contact from './components/Contact'
import Questions from './components/Questions'
import HomeCleaning from './components/HomeCleaning'
import OfficeCleaning from './components/OfficeCleaning'

function App () {
  return (

    <Router>
      <div className='App'>
        <Switch>
          <Route exact path='/'>
            <Landing />
          </Route>
          <Route path='/about-us'>
            <About />
          </Route>
          <Route path='/home-cleaning'>
            <HomeCleaning />
          </Route>
          <Route path='/office-cleaning'>
            <OfficeCleaning />
          </Route>
          <Route path='/contact'>
            <Contact />
          </Route>
          <Route path='/faq'>
            <Questions />
          </Route>
        </Switch>
      </div>
    </Router>

  )
}

export default App
