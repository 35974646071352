import React from 'react'

const Extras = ({ req, setReq }) => {
  const handleChange = (e) => {
    const { checked: isChecked, name } = e.target
    setReq(oldReq => {
      const newReq = JSON.parse(JSON.stringify(oldReq))
      newReq.service.extras[name] = isChecked
      return newReq
    })
  }
  return (
    <fieldset>
      <legend className='block text-base font-medium text-gray-700'>Extra Services</legend>
      <div className='grid grid-cols-8 gap-6'>
        <div className='col-start-2 col-span-2 sm:col-span-2'>
          <div className='mt-4 space-y-4'>
            <div className='flex items-start'>
              <div className='flex items-center h-5'>
                <input
                  onChange={(e) => handleChange(e)}
                  checked={req.service.extras.fridge}
                  id='fridge'
                  name='fridge'
                  type='checkbox'
                  className='focus:ring-transparent h-4 w-4 text-yale border-gray-300 rounded'
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='fridge' className='font-medium text-gray-700'>
                  Fridge
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='col-span-2 sm:col-span-2'>
          <div className='mt-4 space-y-4'>
            <div className='flex items-start'>
              <div className='flex items-center h-5'>
                <input
                  onChange={(e) => handleChange(e)}
                  checked={req.service.extras.oven}
                  id='oven'
                  name='oven'
                  type='checkbox'
                  className='focus:ring-transparent h-4 w-4 text-yale border-gray-300 rounded'
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='oven' className='font-medium text-gray-700'>
                  Oven
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='col-span-2 sm:col-span-2'>
          <div className='mt-4 space-y-4'>
            <div className='flex items-start'>
              <div className='flex items-center h-5'>
                <input
                  onChange={(e) => handleChange(e)}
                  checked={req.service.extras.windows}
                  id='windows'
                  name='windows'
                  type='checkbox'
                  className='focus:ring-transparent h-4 w-4 text-yale border-gray-300 rounded'
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='windows' className='font-medium text-gray-700'>
                  Windows
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  )
}
export default function Service ({ req, setReq }) {
  const handleSelect = (e, field) => {
    const { value } = e.target
    setReq(req => {
      const newReq = JSON.parse(JSON.stringify(req))
      newReq.service[field] = value
      return newReq
    })
  }
  return (
    <form action='#' method='POST'>
      <div className='overflow-hidden sm:rounded-md'>
        <div className='sm:p-0'>
          <div className='grid grid-cols-8 gap-6'>
            <div className='col-start-2 col-span-6 sm:col-span-7'>
              <label htmlFor='servicetype' className='block text-base font-medium text-gray-700'>
                Type of Cleaning
              </label>
              <select
                onChange={e => handleSelect(e, 'type')}
                value={req.service.type}
                id='servicetype'
                name='servicetype'
                autoComplete='servicetype'
                className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-transparent focus:border-alice sm:text-sm'
              >
                <option value='Standard Cleaning'>Standard Cleaning</option>
                <option value='Deep Cleaning'>Deep Cleaning</option>
                <option value='Move-In Cleaning'>Move-In Cleaning</option>
                <option value='Move-Out Cleaning'>Move-Out Cleaning</option>
                <option value='Post-Construction Cleaning'>Post-Construction Cleaning</option>
              </select>
            </div>

            <div className='col-span-8 sm:col-span-6'>
              <Extras req={req} setReq={setReq} />
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
