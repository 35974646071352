import React from 'react'

function Testimonials () {
  return (
    <section id='testimonials' className='bg-yale'>
      <div className='max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8'>
        <div className='py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-dark-yale lg:pr-16'>
          <a href='https://www.yelp.com/biz/qinti-cleaning-marina-del-rey-13' target='_blank' rel='noreferrer'>
            <blockquote className='mt-6 md:flex-grow md:flex md:flex-col'>
              <div className='relative text-lg font-medium text-white md:flex-grow'>
                <svg
                  className='absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-dark-yale'
                  fill='currentColor'
                  viewBox='0 0 32 32'
                  aria-hidden='true'
                >
                  <path d='M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z' />
                </svg>
                <p className='relative'>
                  SUPER happy with our experience with Qinti Cleaning! We were able to quickly get an appointment, and our cleaners showed up on the day of in a timely manner and got straight to work with little direction needed. Qinti Cleaning left our new apartment sparkling clean, and we feel SO much better about our space now! We are both working from home right now and couldn't leave the apartment during the cleaning, and they did a great job working around us. They tackled our 2 bedroom/2 bathroom apartment in 2 hours with 3 cleaners. Unbeatable price and awesome results. We won't hesitate to hire them again when we need a nice, refreshing deep clean!
                </p>
              </div>
              <footer className='mt-8'>
                <div className='flex items-start'>
                  <div className='flex-shrink-0 inline-flex rounded-full border-2 border-white'>
                    <img
                      className='h-12 w-12 rounded-full'
                      src='https://s3-media0.fl.yelpcdn.com/photo/7ObAvf3yNt0cBceIaGCd8Q/60s.jpg'
                      alt="Athena M.'s Yelp icon"
                    />
                  </div>
                  <div className='ml-4'>
                    <div className='text-base font-medium text-white'>Athena M.</div>
                    <div className='text-base font-medium text-alice'>Los Angeles, CA</div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </a>
        </div>
        <div className='py-12 px-4 border-t-2 border-dark-yale sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16'>
          <a href='https://www.yelp.com/biz/qinti-cleaning-marina-del-rey-13' target='_blank' rel='noreferrer'>
            <blockquote className='mt-6 md:flex-grow md:flex md:flex-col'>
              <div className='relative text-lg font-medium text-white md:flex-grow'>
                <svg
                  className='absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-dark-yale'
                  fill='currentColor'
                  viewBox='0 0 32 32'
                >
                  <path d='M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z' />
                </svg>
                <p className='relative'>
                  These women are amazing! Olga, the owner, was such a breeze to work with, and making an appointment was so easy. My apartment has never looked cleaner, pretty much looks like the day I moved in! They cleaned places I didn't even know needed cleaning, and cleaned things I didn't even think they would! They pay attention to the fine fine fine details. They are also very trustworthy. I left my apartment to them alone for 2 hours and came back to nothing out of place.
                </p>
                <br />
                <p className='relative'>Thank you for everything. I can't wait to use your services again.</p>
              </div>
              <footer className='mt-8'>
                <div href='www.google.com' className='flex items-start'>
                  <div className='flex-shrink-0 inline-flex rounded-full border-2 border-white'>
                    <img
                      className='h-12 w-12 rounded-full'
                      src='https://s3-media0.fl.yelpcdn.com/photo/rVzS2Ts5jTcUB2FHSSYOGQ/60s.jpg'
                      alt="Ashley Y.'s Yelp icon"
                    />
                  </div>
                  <div className='ml-4'>
                    <div className='text-base font-medium text-white'>Ashley Y.</div>
                    <div className='text-base font-medium text-alice'>Studio City, CA</div>
                  </div>
                </div>
              </footer>
            </blockquote>

          </a>

        </div>
      </div>
    </section>
  )
}
export default Testimonials
