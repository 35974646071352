import React from 'react'
import Hero from './landing/Hero'
import Stats from './landing/Stats'
import Features from './landing/Features'
import Content from './landing/Content'
import CTA from './landing/CTA'
import Testimonials from './landing/Testimonials'
import Footer from './Footer'

function Landing () {
  return (
    <>
      <Hero />
      <Stats />
      <Features />
      <Content />
      <Testimonials />
      <CTA />
      <Footer onLanding />
    </>
  )
}

export default Landing
