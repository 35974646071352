import React from 'react'
import { CheckIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Locally Owned',
    description: 'No dealing with large, unfriendly companies. We have a heart for our customers.'
  },
  { name: 'Supplies Provided', description: 'Our cleaners bring everything they need to leave your home spotless.' },
  {
    name: 'Custom Cleaning',
    description: 'We\'ll work with you to design the perfect cleaning plan your home needs.'
  },
  { name: 'Fully Insured', description: 'All our cleaners are fully bonded and insured.' },
  { name: 'Happiness Guarantee', description: 'If our cleaning doesn\'t leave you 100% happy, we\'ll fix it for free.' },
  { name: 'Pay How You Want', description: 'We accept all major credit and debit cards, cash, and Venmo.' },
  { name: 'Professional Cleaners', description: 'Our cleaners are employees with years of experience. Not unreliable gig economy workers.' },
  { name: 'No Contracts', description: 'Use or stop our services whenever you want, you\'ll never have to sign a contract.' }
]

function Features () {
  return (
    <div id='whats-included' className='bg-white'>
      <div className='max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8'>
        <div>
          <h2 className='text-base font-semibold text-yale uppercase tracking-wide'>We've got everything you need</h2>
          <p className='mt-2 text-3xl font-extrabold text-gray-900'>Let your home shine.</p>
          <p className='mt-4 text-lg text-gray-500'>
            We've developed a cleaning process guaranteed to leave you (and your home) impressed!
          </p>
        </div>
        <div className='mt-12 lg:mt-0 lg:col-span-2'>
          <dl className='space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8'>
            {features.map((feature) => (
              <div key={feature.name} className='relative'>
                <dt>
                  <CheckIcon className='absolute h-6 w-6 text-green-500' aria-hidden='true' />
                  <p className='ml-9 text-lg leading-6 font-medium text-gray-900'>{feature.name}</p>
                </dt>
                <dd className='mt-2 ml-9 text-base text-gray-500'>{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
export default Features
